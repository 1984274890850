<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({ name: 'others-retentions' })"><icon-arrow-back /></div></div>
	<div class="content opacity-page">
        <div class="header-form">
            <h1 class="title">{{ $route.params.id ? $t('salary.other_payroll.title_edit') : $t('salary.other_payroll.title_new')}}</h1>
            <div class="actions">
                <button class="btn-tbf white only-icon" @click="$router.push({ name: 'others-retentions' })"><icon-close class="icon-close" /></button>
            </div>
        </div>
		<show-contract v-if="userData" :data="userData" />
        <choose-contract v-if="!$route.params.id" :contract="other_payroll" :validator="$v.other_payroll"/>
        <div class="form-group">
            <div class="data-group">
                <div class="title">{{ $t('salary.other_payroll.title') }}</div>
            </div>
            <div class="form">
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('salary.other_payroll.name') }}*</label>
                        <div v-if="$v.other_payroll.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: $v.other_payroll.name.$error}">
                        <div class="icon-left"><icon-retention /></div>
                        <input type="text" class="input-text" :placeholder="$t('salary.other_payroll.name-ph')" v-model="other_payroll.name">
                    </div>
                </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('salary.other_payroll.value') }}*</label>
                        <div v-if="$v.other_payroll.value.$error" class="error-msg">{{ $t('validator.required') }}</div>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: $v.other_payroll.value.$error}">
                        <div class="icon-left"><icon-retention /></div>
                        <input type="number" class="input-text" :placeholder="$t('salary.other_payroll.value-ph')" v-model="other_payroll.value">
                    </div>
                </div>
            </div>
        </div>
	</div>
	<div class="space-right"></div>
    <div class="form-submit crud">
        <div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
        <button class="btn-tbf grey" @click="$router.push({ name: 'others-retentions' })"><span class="text">{{ $t('general.cancel') }}</span></button>
        <button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
            <div class="loader"></div>
            <span class="text">{{ $t('general.add') }}</span>
        </button>
        <button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
            <div class="loader"></div>
            <span class="text">{{ $t('general.update') }}</span>
        </button>
    </div>
</div>
<loader-other-payrolls-create v-else/>
</template>

<script>
	import ShowContract from  '../../../Pages/Contracts/Contracts/ContractDetails/ShowContract'
    import ChooseContract from  '../../../Pages/Contracts/Contracts/ContractDetails/ChooseContract'
    import IconRetention from '../../../Icons/Retention'
	import IconClose from '../../../Icons/Close'
	import IconArrowBack from '../../../Icons/ArrowLong'
	import LoaderOtherPayrollsCreate from '../../../PagesLoaders/UsersCreate'
    import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			ShowContract,
            ChooseContract,
			IconRetention,
			IconClose,
			IconArrowBack,
			LoaderOtherPayrollsCreate
		},
		data() {
			return {
				userData: {},
				loaded: false,
				error_message: '',
                other_payroll: {
                    contractSelected: '',
                    name: '',
					value: ''
                }
			}
		},
        validations: {
            other_payroll: {
                contractSelected: {
                    required: requiredIf(function () {
                        return !this.$route.params.id
                    })
			    },
                name: { required },
				value: { required }
            }
        },
		async mounted(){
			this.userData = localStorage.userData ? JSON.parse(localStorage.userData) : '';
            setTimeout(() => {
                this.loaded = true
                setTimeout(() => {
                    $('.opacity-page').addClass('show')
                }, 0)
            }, 0)

			if(this.$route.params.id){
				await this.getOtherPayrollData()
			}
		},
        methods: {
			async getOtherPayrollData() {
				await axios.get(`/payroll-other-incomes/${this.$route.params.id}`).then(({data}) => {
                    this.other_payroll.name = data.data.name
					this.other_payroll.value = data.data.value
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			
			saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
                if(!this.$v.$invalid){
					let objData = {}

					objData.name = this.other_payroll.name
					objData.value = this.other_payroll.value
					if(this.$route.query.month) {
						  objData.month = this.$route.query.month
					}
					if(this.$route.query.year) {
						objData.year = this.$route.query.year
					}
                    if(type == 'create'){
                        this.createOtherPayroll(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
                    }else if(type == 'update'){
                        this.updateOtherPayroll(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
                    }
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createOtherPayroll(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.other_payroll.contractSelected.id}/payroll-other-incomes/store`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'others-retentions' })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
			updateOtherPayroll(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
				axios.patch(`/payroll-other-incomes/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'others-retentions' })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			}
        }
	}
</script>

<style lang="scss">
    input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
	}
</style>